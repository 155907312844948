
@import "../../sass/component.scss";

.v-accordion__caret {
  color: theme-color("dark");
  transition: transform 0.1s;

  &--small {
    padding: 2px 0;
    transform: rotate(180deg);

    .v-accordion--collapsed & {
      transform: rotate(0);
    }
  }

  &--large {
    --large-scale-mobile: 0.7;
    transform: rotate(-180deg);

    @include media-breakpoint-down(xs) {
      transform: rotate(-180deg) scale(var(--large-scale-mobile));
    }

    color: theme-color("primary");

    .v-accordion--collapsed & {
      transform: rotate(0deg);
      color: #8c8c8d;
      @include media-breakpoint-down(xs) {
        transform: rotate(0deg) scale(var(--large-scale-mobile));
      }
    }
  }
}
