
@import "../../sass/component.scss";

.slider {
  position: relative;

  &__controls {
    display: none;

    @include media-breakpoint-up(md) {
      padding: 0 1vw;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 50%;
      z-index: 10;
    }

    @media screen and (min-width: 1600px) {
      padding: 0 10vw;
    }

    &__arrow-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      background-color: theme-color("white");
      border-radius: 50%;
      width: 28px;
      aspect-ratio: 1;
      box-shadow: 0px 0px 2px 0px theme-color("border");
    }

    &__button {
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    &__container {
      display: flex;
      gap: 10px;
    }
  }

  &__info {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    bottom: 0;
    z-index: 10;

    &--announce {
      bottom: 40px;
    }

    &__dots {
      margin: 0 auto;
    }
  }

  &__title {
    text-transform: uppercase;
  }

  &__track {
    display: grid;
    grid-auto-columns: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      display: flex;
      grid-row: 1 / -1;
      scroll-snap-align: center;
      overflow: hidden;
    }
  }
}
