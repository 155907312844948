
@import "../../sass/component.scss";

.v-flow-step {
  padding: $spacer 0;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    z-index: 2;

    &__center {
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    &:before {
      position: absolute;
      content: "";
      border-top: 2px solid #cccccc50;
      width: 100%;
      top: 28px;
      left: 55%;
      height: 100%;
      z-index: 1;
    }

    &:last-child::before {
      content: none;
    }
  }

  &--done {
    @media screen and (min-width: 769px) {
      &:before {
        position: absolute;
        content: "";
        border-left: 2px solid theme-color("primary");
        width: 100%;
        top: 36px;
        left: 11px;
        height: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      &:before {
        position: absolute;
        content: "";
        border-top: 2px solid theme-color("primary");
        width: 100%;
        top: 28px;
        left: 55%;
        height: 100%;
        z-index: 1;
      }
    }
    &:last-child::before {
      content: none;
    }
  }

  &__icon {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: theme-color("white");
    color: theme-color("placeholder");

    .v-flow-step--done &,
    .v-flow-step--active & {
      color: theme-color("white");
      background-color: theme-color("primary");
    }
  }

  &__title {
    margin-left: $spacer;
    display: flex;
    align-items: center;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
      display: none;
    }

    &--clickable {
      cursor: pointer;
    }

    &--expanded {
      :deep .v-icon {
        transform: rotate(180deg);
      }
    }

    ::v-deep .v-text {
      color: theme-color("placeholder");
      flex-grow: 1;
    }
  }

  &__content {
    padding-top: 12px;
    padding-left: 50px;

    ::v-deep .v-text {
      a {
        display: block;
        margin-top: $spacer * 0.25;
      }

      div + div {
        margin-top: $spacer * 0.25;
      }
    }
  }
}

.v-flow__wrapper__steps .v-flow-step--done:first-child .pulsing {
  &:before {
    display: flex;
    background-color: theme-color("primary");
    border-radius: 50%;
    height: 24px;
    width: 24px;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    content: "";
    z-index: -1;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.4, 1.4);
  }
}
