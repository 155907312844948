
@import "../../../sass/component.scss";

.v-chat-bubble-file {
  display: flex;
  align-items: center;

  &__icon {
    margin-inline-end: $spacer * 0.5;
    margin-block-start: 1px;
    display: flex;
    align-items: center;

    .v-icon {
      width: 18px;
      aspect-ratio: 1;
    }
  }

  &__content {
    display: flex;
    align-items: baseline;
    min-width: 0;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    a {
      color: inherit !important;
      text-decoration: underline;
      overflow: hidden;
      margin-right: 0.5 * $spacer;
      &:is([target="_blank"]) {
        display: inline-flex;
        :deep .v-icon {
          margin-inline-start: $spacer * 0.25;
          margin-block-start: 3px;
        }
      }
    }

    .v-tag {
      margin-top: 0.25 * $spacer;
      margin-bottom: 0.75 * $spacer;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  .v-chat-bubble-file + & {
    margin-top: 0.25 * $spacer;
  }

  @media print {
    margin-top: 0.125 * $spacer !important;

    .v-chat-bubble-file + & {
      margin-top: 0 !important;
    }

    &__icon .v-icon {
      width: 13px;
    }

    &__content {
      a {
        text-decoration: none;
      }

      .v-tag {
        display: none;
      }
    }
  }
}
