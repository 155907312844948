.v-hero-custom {
  --border-radius: 0;
  display: grid;
  grid-template-areas: "stack";
  max-width: calc($container-max-width - ($spacer * 3));
  margin-inline: auto;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);

  @include media-breakpoint-up(md) {
    min-height: var(--height-desktop, auto);
  }

  @include media-breakpoint-up(xl) {
    --border-radius: 0 0 #{$border-radius} #{$border-radius};
  }
  &.fullscreen-mobile {
    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - $nav-height-mobile);
    }
  }
  &.full-height-desktop {
    @include media-breakpoint-up(md) {
      min-height: calc(100vh - $nav-height-desktop + 8px);
    }
  }
  &.full-width-desktop {
    @include media-breakpoint-up(md) {
      max-width: 100%;
      border-radius: 0;
      img,
      .video-embed {
        border-radius: 0;
      }
    }
  }
  &.video-embed {
    background-color: var(--color-white);
  }

  picture {
    grid-area: stack;
    position: relative;
    &.grayscale {
      filter: grayscale(100%) brightness(0.8);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      border-radius: var(--border-radius);
    }
  }

  .video-embed {
    grid-area: stack;
    position: relative;
    overflow: hidden;
    background-color: var(--color-white);
    border-radius: var(--border-radius);
    &.grayscale {
      filter: grayscale(100%) brightness(0.8);
    }
    section {
      position: absolute;
      overflow: hidden;
      inset: 0;
      :deep(> *) {
        border-radius: 0;
        height: 100%;
        width: 200%;
        transform: translateX(-25%);
        display: block;
        @media (orientation: portrait) {
          @include media-breakpoint-up(md) {
            width: 400%;
            transform: translateX(-42%);
          }
        }

        &:has(iframe[src*="controls=false"]) {
          pointer-events: none;
        }
      }
      &.video-embed--mobile {
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      &.video-embed--desktop {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  .color-overlay {
    grid-area: stack;
    position: relative;
    pointer-events: none;
    background-color: var(--overlay-color, rgba(0, 0, 0, 0.5));
    border-radius: var(--border-radius);
  }

  article {
    width: 100%;
    overflow: hidden;
    grid-area: stack;
    display: flex;
    flex-direction: column;
    justify-content: var(--v-align-content-mobile, bottom);
    padding-block-start: $spacer * 4;
    padding-block-end: $spacer * 6;
    padding-inline: $spacer * 2;

    @include media-breakpoint-up(md) {
      justify-content: var(--v-align-content-desktop, center);
      padding-block: $spacer * 6;
      padding-inline: $spacer * 2.5;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;

    &.align-mobile {
      &--center {
        @include media-breakpoint-down(sm) {
          text-align: center;
          justify-content: center;
        }
      }
      &--left {
        @include media-breakpoint-down(sm) {
          text-align: left;
          justify-content: left;
        }
      }
      &--right {
        @include media-breakpoint-down(sm) {
          text-align: right;
          justify-content: right;
        }
      }
    }
    &.align-desktop {
      &--center {
        @include media-breakpoint-up(md) {
          text-align: center;
          margin-inline: auto;
          max-width: 800px;
          justify-content: center;
        }
      }
      &--left {
        @include media-breakpoint-up(md) {
          text-align: left;
          max-width: 50%;
          justify-content: left;
          @media (orientation: portrait) {
            max-width: 80%;
          }
        }
      }
      &--right {
        @include media-breakpoint-up(md) {
          max-width: 50%;
          text-align: left;
          margin-inline-start: auto;
          justify-content: left;
          @media (orientation: portrait) {
            max-width: 80%;
          }
        }
      }
    }

    // Hide content-container if no content is present:
    &:not(:has(*)) {
      display: none !important;
    }
  }

  .title-container {
    color: var(--title-color, var(--color-gray-dark));
    text-align: inherit;
    overflow: hidden;

    & > * {
      color: inherit;
      text-align: inherit;
      display: inline-block;
      margin-block-end: 0 !important; // override class from v-text component
      @include media-breakpoint-down(sm) {
        line-height: 1.0;
      }
    }
    &.text-shadow > * {
      text-shadow: var(--text-shadow);
    }

    // Hide title-container if no content is present:
    &:not(:has(*)) {
      display: none !important;
    }
  }

  .text-container {
    color: var(--text-color, var(--color-gray-dark));
    text-align: inherit;
    overflow: hidden;

    & > * {
      color: inherit;
      text-align: inherit;
      display: inline-block;
      @include media-breakpoint-down(sm) {
        line-height: 1.3;
      }
    }
    &.text-shadow > * {
      text-shadow: var(--text-shadow);
    }
    // Remove margin from last paragraph
    & > *:last-child,
    & > *:last-child :deep > p:last-child {
      margin-block-end: 0;
    }

    // Hide text-container if no content is present:
    &:not(:has(*)) {
      display: none !important;
    }
  }
}
