.v-hero-split {
  display: grid;

  // Desktop layout (2 columns)
  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    align-items: stretch;

    // Content right - Media left
    &[data-content-position-desktop='right'] {

      .media {
        order: 1;
        border-end-start-radius: #{$border-radius};
        &:not(.has-theme) {
          border-end-end-radius: #{$border-radius};
        }
      }

      .article {
        order: 2;
        border-end-end-radius: #{$border-radius};
        &:not(.has-theme) {
          padding-inline-end: 0;
        }
      }
    }

    // Content left - Media right
    &[data-content-position-desktop='left'] {

      .media {
        order: 2;
        border-end-end-radius: #{$border-radius};
        &:not(.has-theme) {
          border-end-start-radius: #{$border-radius};
        }
      }

      .article {
        order: 1;
        border-end-start-radius: #{$border-radius};
        &:not(.has-theme) {
          padding-inline-start: 0;
        }
      }
    }
  }

  // Mobile layout (stacked)
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;

    .article {
      &.align-mobile {
        &--center {
          text-align: center;
          justify-content: center;
        }

        &--left {
          text-align: left;
          justify-content: left;
        }

        &--right {
          text-align: right;
          justify-content: right;
        }
      }
    }

    // Content bottom - Media top
    &[data-content-position-mobile='bottom'] {

      .media {
        order: 1;
      }

      .article {
        order: 2;
      }
    }

    // Content top - Media bottom
    &[data-content-position-mobile='top'] {

      .media {
        order: 2;
      }

      .article {
        order: 1;
      }
    }
  }
}
