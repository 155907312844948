
@import "../../sass/component.scss";

.v-accordion {
  width: 100%;

  &__wrapper {
    position: relative;
    background: theme-color("light");
    border-radius: 0.3rem;
  }

  &--collapsed {
    cursor: pointer;
    user-select: none;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: inherit;
  }

  &__title {
    padding: 0 $spacer;
    padding-right: 2.5rem;
    cursor: pointer;
    height: 58px;
    overflow: hidden;
    font-weight: bold;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-accordion--collapsed & {
      font-weight: normal;
    }

    svg {
      flex: 0 0 14px;
      margin-left: auto;
    }

    .text-capitalize-first & {
      span::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__tags {
    display: none;
  }

  &__content {
    padding: 0 $spacer * 3 $spacer $spacer;

    .v-accordion--collapsed & {
      display: none;
    }

    .v-accordion--standalone & {
      cursor: pointer;
      padding-top: $spacer;
      display: inline-block;
      max-width: 100%;
    }

    .v-accordion--no-toggle & {
      cursor: default;
    }

    .v-accordion--standalone.v-accordion--collapsed & {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ::v-deep .v-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.v-accordion--large {
  background: white;
  box-shadow: $box-shadow-lg;

  &.v-accordion--collapsed {
    background: theme-color("light");
    box-shadow: none;
  }

  .v-accordion__header {
    display: flex;
    align-items: center;
  }

  .v-accordion__close {
    top: 13px;
    left: 4px;
    right: unset;

    @include media-breakpoint-up(md) {
      top: 24px;
      left: 18px;
    }
  }

  .v-accordion__title {
    padding: 0 5px 0 40px;
    height: 66px;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    flex-grow: 1;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include media-breakpoint-up(md) {
      height: 89px;
      font-size: 20px;
      padding-left: 63px;
    }
  }

  &.v-accordion--collapsed {
    .v-accordion__title {
      color: #8c8c8d;
    }
  }

  .v-accordion__tags {
    display: block;
    margin-right: 16px;
    flex-shrink: 0;

    .v-tag {
      margin-left: 6px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 30px;
    }
  }

  .v-accordion__content {
    padding: 0 16px 16px 16px;

    @include media-breakpoint-up(md) {
      padding: 0 30px 30px 30px;
    }
  }
}

@media print {
  .v-accordion {
    page-break-inside: avoid;

    &__content {
      display: block !important;
    }

    &__title {
      font-weight: bold !important;
    }
  }
}
