
@import "../../sass/component.scss";

.v-icon {
  display: inline-flex;
  align-items: center;

  &--color-light {
    color: theme-color("light") !important;
  }
  &--color-dark {
    color: theme-color("dark") !important;
  }
  &--color-primary {
    color: theme-color("primary") !important;
  }

  &__content {
    margin-left: 0.5 * $spacer;
  }

  $this: &;

  &__scaler {
    display: inline-flex;
    align-items: center;

    &#{$this}--xxsmall {
      width: 16px;
    }

    &#{$this}--xsmall {
      width: 24px;
    }

    &#{$this}--small {
      width: 40px;
    }

    &#{$this}--medium {
      width: 60px;
    }

    &#{$this}--large {
      width: 80px;
    }

    &#{$this}--xlarge {
      width: 100px;
    }
  }

  img,
  svg {
    width: 100%;
  }
}
