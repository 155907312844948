
@import "../../../sass/component.scss";

.v-chat-composer {
  position: relative;

  &__card {
    width: inherit;
    background: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14);
    background: #fff;
    border-radius: $border-radius-lg;
    padding: $spacer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: start;
    }
  }

  ::v-deep .v-chat-composer__message {
    width: 100%;
    flex-grow: 1;
  }

  ::v-deep .v-chat-composer__actions {
    margin-top: $spacer;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
      margin-top: $spacer * 0.25;
    }
  }

  ::v-deep .v-chat-composer__add-files {
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin: 0 $spacer;

    &::before {
      content: "";
      display: block;
      margin-right: $spacer * 0.5;
      width: 24px;
      aspect-ratio: 1;
      color: theme-color("focus");
      background-image: url("./assets/attachment.svg");
    }
  }

  ::v-deep .v-chat-composer__file {
    user-select: none;
    display: inline-flex;
    align-items: center;
    margin-top: $spacer * 0.5;
    margin-right: $spacer * 1;
    cursor: default;

    span {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::before {
      content: "";
      display: block;
      margin-inline-end: $spacer * 0.5;
      margin-block-start: 1px;
      width: 18px;
      aspect-ratio: 1;
      background-image: url("./assets/attachment.svg");
    }

    &__remove {
      width: 15px;
      aspect-ratio: 1;
      position: relative;
      cursor: pointer;
      margin-inline-start: $spacer * 0.25;
      margin-block-start: 1px;
      background-image: url("./assets/close.svg");
      border-radius: 4px;
      transition: background-color 0.1s ease, box-shadow 0.1s ease;
      &:hover {
        background-color: color-mix(in srgb, currentColor 10%, transparent);
        box-shadow: 0 0 0 2px color-mix(in srgb, currentColor 10%, transparent);
      }
    }
  }

  @media print {
    display: none;
  }
}
