.v-hero-split {
  width: -webkit-fill-available;
  width: -moz-available;
  max-width: $container-max-width;
  margin-inline: auto;
  margin-block-end: 0.5rem;
  min-height: var(--height-desktop, 600px);
  overflow: hidden;
  border-radius: 0;

  @include media-breakpoint-up(md) {
    padding-inline: var(--responsive-spacer);
    margin-block-end: 0;

    &.is-wide {
      max-width: calc(#{$container-max-width} + ((#{$container-max-width} / #{$grid-columns}) * 2));
    }
  }

  .media {
    position: relative;
    overflow: hidden;
  }

  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .video {
    width: 100%;
    height: 100%;

    .embed {
      width: 100%;
      height: 100%;

      &:deep(> section) { // our embed wrapper in slots
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          position: absolute;
          inset: 0;
        }

        & > div { // the embed itself from Cloudflare
          border-radius: 0;
          height: 100%;
          width: 100%;
          display: block;

          // this should be changed to a better solution like container queries
          @include media-breakpoint-up(md) {
            width: 400%;
            transform: translateX(-42%);
          }

          &:has(iframe[src*="controls=false"]) {
            pointer-events: none;
          }
        }
      }
    }
  }

  .article {
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding: 3rem clamp(1.5rem, 3vw, 2.5rem);
    margin: 0 auto;
  }

  .button-container {
    @include media-breakpoint-up(md) {
      justify-content: left;
    }
  }
}
