.button-container {
  // SHARED button styles for all hero components:
  // Do not add color styles to this file as the should be set in the specific hero component stylesheet file

  justify-content: inherit;
  display: flex;
  flex-wrap: wrap;
  gap: $spacer * 1.5;
  align-items: center;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    justify-content: inherit;
  }

  &:has(.v-button--link) {
    gap: $spacer * 0.75;
  }

  // Hide button container if no buttons are present:
  &:not(:has(.v-button)) {
    display: none !important;
  }

  // if buttons are wrapped in a div from the v-data component:
  & > .v-data {
    justify-content: inherit;
    display: inherit;
    flex-wrap: inherit;
    gap: inherit;
    align-items: inherit;
    overflow: inherit;
  }

  // Remove margin from v-button as we use gap for spacing:
  :deep(.v-button) {
    margin: 0 !important
  }
}
