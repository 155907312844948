
@import "../../sass/component.scss";

.v-toast {
  cursor: pointer;
  margin-bottom: $spacer;
  max-width: 400px;
  position: relative;
  padding: $spacer;
  border-radius: $border-radius * 0.5;
  transition: all 250ms ease-out;
  box-shadow: 0 18px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  &__content {
    color: theme-color("white");

    ::v-deep a {
      font-weight: inherit;
      color: inherit !important;
      text-decoration: underline;

      &:hover,
      &:visited {
        color: inherit !important;
      }
    }
  }

  &__close {
    position: absolute;
    top: 6px;
    right: 6px;
    color: theme-color("white");
  }

  &__icon {
    position: relative;
    color: theme-color("white");
    top: 3px;
    margin-right: $spacer * 0.5;
    .v-icon {
      width: 28px;
      aspect-ratio: 1;
    }
  }

  &-enter-active {
    transition-delay: 500ms;
  }

  &-enter,
  &-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

  &--info {
    background-color: theme-color("secondary");
  }

  &--error {
    background-color: theme-color("danger");
  }

  &--success {
    background-color: theme-color("success");
  }
}
