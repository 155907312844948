.v-navigation-card {
  --gap: 0.6rem;
  @container component (min-width: 768px) {
    --gap: 1rem;
  }
  container: cards / inline-size;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  height: auto;
  min-height: 100%;
  padding: 2em;
  background-color: var(--color-gray);
  color: var(--color-gray-dark);
  border-radius: $border-radius;
  overflow: clip;
  transition: filter 0.2s ease, gap 0.2s ease;

  &:is(a) {
    text-decoration: none !important;
    cursor: pointer;
    &:hover {
      filter: brightness(0.985);
      .nav-icon {
        transform: translateX(4px);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    flex: 1;
    transition: gap 0.2s ease;

    & + .footer {
      padding-top: calc(var(--gap) * 2);
    }
  }

  .card-icon {
    width: 1.6rem;
    opacity: 0.9;
    margin-bottom: calc(var(--gap) / 2);
  }

  .title {
    //font-size: 1.3rem;
    font-size: clamp(1rem, 10cqi, 1.5rem);
    line-height: 1.25;
    font-weight: bold;
    margin-top: 5px;
    border: none;
    margin: 0;
    padding: 0;
  }

  .text {
    font-size: 1rem;
    line-height: 1.45;
    margin-bottom: 0;
  }

  .nav-icon {
    transition: transform 0.3s ease;
    display: flex; // Remove weird space after icon
  }

}
