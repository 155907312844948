// Dependencies: src/directives/v-theme/index.js
// Do not use these classes directly, use the v-theme directive instead:
// <v-component v-theme="{ variant: 3, buttons: true }">...</v-component>
.has-theme {

  --theme-0-fg: var(--color-dark);
  --theme-0-bg: var(--color-light);

  --theme-1-fg: var(--color-gray-dark);
  --theme-1-bg: var(--color-gray);

  --theme-2-fg: var(--color-white);
  --theme-2-bg: var(--color-primary);

  --theme-3-fg: var(--color-white);
  --theme-3-bg: var(--color-corporate);

  --theme-4-fg: var(--color-corporate);
  --theme-4-bg: var(--color-sky);

  --theme-5-fg: var(--color-corporate);
  --theme-5-bg: var(--color-warm-sand);

  --theme-6-fg: var(--color-corporate);
  --theme-6-bg: var(--color-spark);

  --theme-7-fg: var(--color-primary);
  --theme-7-bg: var(--color-warm-sand);

  --theme-8-fg: var(--color-primary);
  --theme-8-bg: var(--color-spark);

  --theme-9-fg: var(--color-primary);
  --theme-9-bg: var(--color-sky);

  --theme-10-fg: var(--color-white);
  --theme-10-bg: var(--color-spark);

  &.theme-variant {
    .app  & {
      &-0 {
        // default theme variant 0 / fallback
        color: var(--theme-0-fg) !important;
        background-color: var(--theme-0-bg) !important;
      }
      &-1 {
        // theme variant 1
        color: var(--theme-1-fg) !important;
        background-color: var(--theme-1-bg) !important;
      }
      &-2 {
        // theme variant 2
        color: var(--theme-2-fg) !important;
        background-color: var(--theme-2-bg) !important;
        // special case for buttons because the default button color matches the theme-2
        .v-button--filled:not(.v-button--inverted) {
          color: var(--theme-2-bg) !important;
          background-color: var(--theme-2-fg) !important;
        }
        .v-button--link {
          color: var(--theme-2-fg) !important;
        }
      }
      &-3 {
        // theme variant 3
        color: var(--theme-3-fg) !important;
        background-color: var(--theme-3-bg) !important;
      }
      &-4 {
        // theme variant 4
        color: var(--theme-4-fg) !important;
        background-color: var(--theme-4-bg) !important;
      }
      &-5 {
        // theme variant 5
        color: var(--theme-5-fg) !important;
        background-color: var(--theme-5-bg) !important;
      }
      &-6 {
        // theme variant 6
        color: var(--theme-6-fg) !important;
        background-color: var(--theme-6-bg) !important;
      }
      &-7 {
        // theme variant 7
        color: var(--theme-7-fg) !important;
        background-color: var(--theme-7-bg) !important;
      }
      &-8 {
        // theme variant 8
        color: var(--theme-8-fg) !important;
        background-color: var(--theme-8-bg) !important;
      }
      &-9 {
        // theme variant 9
        color: var(--theme-9-fg) !important;
        background-color: var(--theme-9-bg) !important;
      }
      &-10 {
        // theme variant 10
        color: var(--theme-10-fg) !important;
        background-color: var(--theme-10-bg) !important;
      }
    }
  }

  &.has-theme-buttons {
    .v-button--filled {
      color: inherit !important;
      border: 2px solid currentColor !important;
      background-color: transparent !important;
      box-shadow: none !important;
      &:hover {
        filter: brightness(0.95);
        background-color: color-mix(
          in srgb,
          currentColor 25%,
          transparent
        ) !important;
      }
    }
    .v-button--link {
      color: inherit !important;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
