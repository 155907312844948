.v-hero-custom .button-container {
 // Specific button-container + v-button styles only for the v-hero-custom component
 // (Also see shared-hero-button-styles.scss)

  :deep(.v-button--filled) {
    background-color: var(--button-bg-color, var(--color-primary)) !important;
    border: 2px solid var(--button-bg-color, var(--color-primary)) !important;
    color: var(--button-color, var(--color-white)) !important;
    transition: all 0.1s;
    &:hover {
      filter: brightness(0.95);
    }
  }
  :deep(.v-button--link) {
    color: var(--button-color, var(--color-primary)) !important;
    &:hover {
      text-decoration: underline;
    }
  }
  :deep(.v-button--filled):nth-child(2) {
    background-color: var(--button-2-bg-color, var(--color-white)) !important;
    border: 2px solid var(--button-2-bg-color, var(--color-white)) !important;
    color: var(--button-2-color, var(--color-primary)) !important;
  }
  :deep(.v-button--link):nth-child(2) {
    color: var(--button-2-color, var(--color-primary)) !important;
  }
  &:not(.text-shadow) {
    :deep(.v-button) {
      box-shadow: none !important;
    }
  }
  &.transparent-buttons {
    :deep(.v-button) {
      background-color: transparent !important;
      color: var(--button-bg-color, var(--color-primary)) !important;
      &.v-button--filled:hover {
        background-color: color-mix(in srgb, var(--button-bg-color) 25%, transparent) !important;
      }
    }
    :deep(.v-button:nth-child(2)) {
      background-color: transparent !important;
      color: var(--button-2-bg-color, var(--color-white)) !important;
      &.v-button--filled:hover {
        background-color: color-mix(in srgb, var(--button-2-bg-color) 25%, transparent) !important;
      }
    }
  }
}
