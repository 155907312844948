
@import "../../sass/component.scss";
.corner-button {
  position: fixed;
  z-index: 999;
  inset-block-end: 1rem;
  inset-inline-end: 1rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: theme-color("primary");
  border: none;
  outline: none;
  color: theme-color("white");
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &.red {
    background-color: theme-color("primary");
    color: theme-color("white");
  }

  &.white {
    background-color: theme-color("white");
    color: theme-color("primary");
  }

  &.black {
    background-color: theme-color("dark");
    color: theme-color("white");
  }
}
