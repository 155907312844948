// Carrousel styling:
.v-navigation-cards__carrousel {
  container: component / inline-size;
  .swiper {
    --swiper-navigation-size: 22px;
    --swiper-navigation-top-offset: 50%;
    --swiper-navigation-sides-offset: 6px;
    --swiper-scrollbar-size: 5px;
    --swiper-scrollbar-bottom: 0px;
    --swiper-theme-color: var(--color-gray-dark);
    padding-block-end: 10px; // to make space for the scrollbar
  }
  .swiper-wrapper {
    align-items: stretch;
    cursor: default;
    // if the swiper should be locked:
    // &:has(+ .swiper-button-lock),
    // &:has(+ .swiper-scrollbar-lock) {

    // }
  }
  .swiper-button-prev,
  .swiper-button-next {
    transition: all 0.3s ease;
    outline: none;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &:not(:hover) {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0;
      --swiper-navigation-sides-offset: 0;
    }
  }
  .swiper-scrollbar {
    opacity: 0.3 !important;
    transition: opacity 0.3s ease !important;
    &:hover,
    &:active {
      opacity: 1 !important;
      cursor: grab !important;
    }
  }
  .swiper-slide {
    // Responsive handling: ToDo
    @container component (min-width: 768px) {
      //width: calc(33.3333% - (32px/1.5)) !important; // not working with swiper auto height and slidesPerView: auto
    }
  }

}
