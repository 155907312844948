
.v-speak-text {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  :deep svg {
    margin-inline-end: 2px;
    margin-block-end: 1px;
  }

  &__label {
    font-size: 0.9rem;
    text-transform: none;
  }
}
